<template>
  <div v-if="value && isRepairBooked"
    class="tile is-parent"
    :class="{'box  ml-2': showBox}">
    <article class="tile is-child">
      <div class="columns mb-0">
        <div class="column is-flex is-align-items-center">
          <i class="icon mdi mdi-car mdi-24px" />
          <p class="modal-card-title pl-1">Repair</p>
        </div>
      </div>
      <div class="is-divider" />
      <div class="tile is-ancestor is-parent">
        <div class="tile">
          <div class="tile is-parent is-half">
            <article class="tile is-child detail-page-tile">

              <div class="columns field">
                <div class="column">
                  <div class="field">
                    <label class="label">Is Arrive For Repair?</label>
                  </div>
                  <div class="field">
                    <div class="pretty p-default p-round p-bigger p-smooth">
                      <input type="radio"
                        id="text"
                        value="true"
                        v-model="value.isArriveForRepair"
                        @change="handleIsArriveForRepairChange">
                      <div class="state p-primary-o">
                        <label>Yes</label>
                      </div>
                    </div>
                    <div class="pretty p-default p-round p-bigger p-smooth">
                      <input type="radio"
                        id="text"
                        value="false"
                        v-model="value.isArriveForRepair"
                        @change="handleIsArriveForRepairChange">
                      <div class="state p-primary-o">
                        <label>No</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field"
                v-if="isArrivedForRepair">
                <label class="label">Arrive For Repair Date</label>
                <v-date-picker v-model="value.arriveForRepairDate"
                  ref="datepicker"
                  mode="dateTime"
                  :minute-increment="5"
                  :masks="formats"
                  :attributes="attrs"
                  :locale="$userInfo.locale"
                  class="is-fullwidth"
                  :timezone="$filters.getIanaTimezone()">
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="field has-addons">
                      <div class="control is-expanded has-icons-left">
                        <input type="text"
                          class="input"
                          placeholder="Arrive For Inspection Date"
                          :value="inputValue"
                          v-on="inputEvents"
                          readonly>
                        <span class="icon is-small is-left">
                          <i class="mdi mdi-calendar mdi-18px" />
                        </span>
                      </div>
                    </div>
                  </template>
                </v-date-picker>
              </div>
              <div class="field"
                v-if="!isArrivedForRepair">
                <label class="label">Refuse Book Repair Reason</label>
                <div class="control">
                  <textarea class="textarea"
                    v-model="value.refuseBookRepairReason"
                    placeholder="Add reason here"
                    v-focus-inserted />
                </div>
              </div>

              <div class="field"
                v-if="isArrivedForRepair">
                <label class="label">Repair Start Date</label>
                <v-date-picker v-model="value.repairStartDate"
                  ref="datepicker"
                  mode="dateTime"
                  :minute-increment="5"
                  :masks="formats"
                  :attributes="attrs"
                  :locale="$userInfo.locale"
                  class="is-fullwidth"
                  :model-config="value.repairStartDate ? {} : modelConfig"
                  :timezone="$filters.getIanaTimezone()"
                  :min-date="value.repairEndDate">
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="field has-addons">
                      <div class="control is-expanded has-icons-left">
                        <input type="text"
                          class="input"
                          placeholder="Repair Start Date"
                          :value="inputValue"
                          v-on="inputEvents"
                          readonly
                          ref="repairStartDate">
                        <span class="icon is-small is-left">
                          <i class="mdi mdi-calendar mdi-18px" />
                        </span>
                      </div>
                      <div class="control">
                        <a class="button tooltip"
                          data-tooltip="Reset dates"
                          @click="clear('repairStartDate')">
                          <span class="icon">
                            <i class="mdi mdi-close mdi-24px" />
                          </span>
                        </a>
                      </div>
                    </div>
                  </template>
                </v-date-picker>
              </div>
              <div class="field"
                v-if="isArrivedForRepair">
                <label class="label">Repair End Date</label>
                <v-date-picker v-model="value.repairEndDate"
                  ref="datepicker"
                  mode="dateTime"
                  :minute-increment="5"
                  :masks="formats"
                  :attributes="attrs"
                  :locale="$userInfo.locale"
                  class="is-fullwidth"
                  :model-config="value.repairEndDate ? {} : modelConfig"
                  :timezone="$filters.getIanaTimezone()"
                  :min-date="value.repairStartDate">
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="field has-addons">
                      <div class="control is-expanded has-icons-left">
                        <input type="text"
                          class="input"
                          placeholder="Repair End Date"
                          :value="inputValue"
                          v-on="inputEvents"
                          readonly
                          ref="repairEndDate">
                        <span class="icon is-small is-left">
                          <i class="mdi mdi-calendar mdi-18px" />
                        </span>
                      </div>
                      <div class="control">
                        <a class="button tooltip"
                          data-tooltip="Reset dates"
                          @click="clear('repairEndDate')">
                          <span class="icon">
                            <i class="mdi mdi-close mdi-24px" />
                          </span>
                        </a>
                      </div>
                    </div>
                  </template>
                </v-date-picker>
              </div>
              <div class="field">
                <label class="label is-required-label">Repair Late Reason</label>
                <div class="control is-extended">
                  <div class="select is-fullwidth">
                    <select v-model="value.repairLateReason">
                      <option v-for="(s, index) in storeRepairDelayReasons"
                        :key="index"
                        :value="s.id">
                        {{ s.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <!-- <div class="field"
                v-if="isArrivedForRepair">
                <label class="label">Repair Late Reason</label>
                <div class="control">
                  <textarea class="textarea"
                    v-model="value.repairLateReason"
                    placeholder="Add reason here"
                    v-focus-inserted />
                </div>
              </div> -->
              <div class="field"
                v-if="isArrivedForRepair">
                <label class="label">Repair Late Reason Details</label>
                <div class="control">
                  <textarea class="textarea"
                    v-model="value.repairLateReasonDetails"
                    placeholder="Add reason here"
                    v-focus-inserted />
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </article>
  </div>

</template>

<script>
// import QuickItemService from './QuickItemService'
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'
import { FocusInserted } from '@/components/directives'
import storeMixin from '../../storeMixin'
import { mapGetters } from 'vuex'

export default {
  name: 'BookingVehicle',
  inject: ['$vv'],
  components: {
    // QuickItemService,
  },
  directives: {
    FocusInserted
  },
  mixins: [DateTimeFiltersMixin, NumberFiltersMixin, storeMixin],
  props: {
    value: null,
    isNew: Boolean,
    showBox: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      modelConfig: {
        start: {
          timeAdjust: '08:00:00'
        },
        end: {
          timeAdjust: '08:00:00'
        }
      }
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    ...mapGetters('booking', {
      storeRepairDelayReasons: 'repairDelayReasons'
    }),
    isRepairBooked() {
      const isRepairBooked = (this.value && this.value.isRepairBooked === 'true') || this.value.isRepairBooked === true
      return isRepairBooked
    },
    isArrivedForRepair() {
      const isArriveForRepair = (this.value && this.value.isArriveForRepair === 'true') || this.value.isArriveForRepair === true
      return isArriveForRepair
    }
  },
  watch: {
    value: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  created() {
    this.$store.dispatch('booking/load')
  },
  mounted() {
    this.$v.detailGroup.$touch()
  },
  methods: {
    toggleActive(deleted) {
      this.value.deleted = deleted
    },
    clear(input) {
      const inputEle = this.$refs[input]
      if (inputEle) {
        this.value[input] = ''
      }
    },
    clearInputs(inputs, value) {
      if (value === 'false' || value == false || value === '' || value == null) {
        inputs.forEach((input) => {
          const key = Object.keys(input)[0]
          if (this.value.hasOwnProperty(key)) {
            this.value[key] = input[key]
          } else {
            console.warn(`Key ${key} does not exist in value object`)
          }
        })
      }
    },
    handleIsArriveForRepairChange() {
      const inputs = [
        {'refuseBookRepairReason': ''},
        {'refuseBookRepairReason': ''},
        {'arriveForRepairDate': null},
        {'repairStartDate': null},
        {'repairEndDate': null},
        {'repairLateReason': ''},
        {'repairLateReasonDetails': ''}
      ]
      this.clearInputs(inputs, this.value.isArriveForRepair)
    }
  }
}
</script>
<style lang="scss" scoped>
.is-divider {
  margin: 1.2em 0;
}
.desc-width {
  width: 50%;
}
</style>
